.coincidencesMenu {
  padding: 0 16px;
}

.menuTabs {
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  margin-bottom: 8px;
}

.tab {
  width: 33.3%;
  padding: 0 5px;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #666;
}

.active {
  color: #000;
}


