@import "scss/colors";

.container {
  height: 5px;
  border-radius: 12px;
  border: 1px solid transparent;
  background-color: #ffffff;
  padding: 1px 1px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
}

.step {
  display: block;
  position: absolute;
  left: 1px;
  background-color: $accent-and-links;
  border-radius: 12px;
  height: 3px;
  width: calc(1 / 3 * 100% - 0.5%);
  transition: 0.5s;
}

.secondStep {
  left: calc(1 / 3 * 100%);
}

.thirdStep {
  left: calc(2 / 3 * 100%);
}
