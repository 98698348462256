.titleField {
  height: 60px;
  padding: 19px 16px;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;

  span {
    color: #98989e;
    display: block;
  }
}

.selects {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.dropdownContainer {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 4px 16px;
  cursor: pointer;
  min-width: 114px;
  height: 50px;
  position: relative;
}

.dropdownLabel {
  display: block;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #98989e;
  margin-bottom: 4px;
}

.valueAndArrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  width: 100%;
  outline: none;
  border: 1px solid transparent;
  font-size: 17px;
  line-height: 22px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.select {
  position: absolute;
  left: 0;
  z-index: 3;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  &_item {
    cursor: pointer;
    text-align: left;
    padding: 19px 16px;
    font-size: 17px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #98989e;
    border-radius: 10px;
    height: 60px;
  }
}
