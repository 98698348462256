.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.addPhotoContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 8px;
  margin: auto;
}

.addPhotoItem {
  position: relative;
  width: 175px;
  height: 220px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  overflow: hidden;
  margin: auto;
}

.iconLabel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hiddenInputBtn {
  display: none;
}

.previewImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.crossIcon{
  position: absolute;
  top: 8px;
  right: 8px;
  height: 24px;
  width: 24px;
}