.container {
  height: 58px;
  padding: 19px 16px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    height: 100%;
    border: none;
    outline: none;
    background-color: white;
    font-size: 17px;
    color: #98989e;

    &::placeholder {
      color: #98989e;
      font-size: 17px;
    }
  }

  .contentContainer {
    display: flex;
    justify-content: space-between;
  }

  .text {
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.modal {
  width: 270px;
  height: 184px;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 50%;
  right: 50%;
  border-radius: 10px;
  overflow: hidden;

  .modalText {
    text-align: center;
    width: 197px;
    height: 50%;
    margin: 0 auto;
    margin-top: 19px;

    p {
      font-size: 17px;
      font-weight: 600;
      line-height: 22px;
      color: black;
    }
  }

  .modalButtons {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    background: white;

    .alowButton {
      height: 50%;
      border: none;
      color: #007aff;
      background-color: white;
      border-bottom: 0.33px solid #98989e;

      &:hover {
        cursor: pointer;
      }
    }

    .denyButton {
      height: 50%;
      border: none;
      background-color: white;
      color: #ff3b30;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.group {
  display: flex;
  gap: 8px;
}
