.registerStepThree {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f2f2f7;
  padding: 0 16px;
}

.title {
  margin-bottom: 24px;
}

.button {
  position: absolute;
  bottom: 68px;
  left: 16px;
  right: 16px;
}

.checkboxContainer {
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;

  .checkboxLabel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    left: -16px;
  }

  .customRadio {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    border-radius: 50%;
    border: 2px solid rgb(205, 205, 205);
    position: relative;
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;

    &:checked {
      background-color: #32ade6;
      border-color: #32ade6;
    }

    &:checked::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 73%;
      width: 10px;
      height: 5px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      transform: translate(-50%, -36%) rotate(-50deg);
      transform-origin: bottom left;
    }
  }

  .infos {
    text-align: left;
    font-size: 12px;
    margin-left: 2px;
    color: #98989e;

    span {
      border-bottom: 1px solid #98989e;
    }
  }
}