.EditProfileContainer{
    overflow-y: auto;  /* Включает вертикальную прокрутку */
    position: relative; /* Можно добавить для работы с абсолютными элементами */
  
    /* Для Firefox */
    scrollbar-width: none; /* Убирает полосу прокрутки */
  
    /* Для других браузеров */
    &::-webkit-scrollbar {
      display: none; /* Скрывает полосу прокрутки */
    }
}