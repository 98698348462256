.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.71);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition:
    opacity 0.5s,
    visibility 0.5s;
  opacity: 1;
  visibility: visible;
}

.iconsGroup {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.container {
  width: 242px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconGroup {
  display: flex;
  gap: 8px;
}

.iconGroupText {
  font-size: 12px;
  color: #fff;
  text-align: left;

  span {
    display: block;
    color: #98989e;
    font-size: 10px;
    margin-top: 4px;
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 32px;
  color: #fff;
}

.isClosed {
  opacity: 0;
  visibility: hidden;
}
