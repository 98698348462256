.mainContainer {
  width: 358px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 16px;
}
.filterIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.container {
  width: 275px;
  border-radius: 10px;
  padding: 4px 3px 2px 3px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10;
  position: relative;
  .button {
    width: 50%;
    height: 36px;
    border-radius: 7px;
    padding: 5px 47px 5px 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.4s;
    p {
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
      color: black;
    }
    .activeTextCommon {
      color: white;
    }

    .activeTextLocal {
      color: white;
    }
  }
}

.active {
  background-color: #32ade6;
  p {
    color: white;
  }
}

.activeTextCommon {
  color: white;
}

.activeTextLocal {
  color: white;
}

.filterMenu {
  position: absolute;
  top: 45px;
  z-index: 2;
}
