.container {
  display: flex;
  flex-direction: column;
  margin-top: 16px auto;
  justify-content: center;
  margin: 0 auto;
}

.titleContainer {
  max-width: 358px;

  .titleText {
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
}

.textContainer {
  width: 358px;
  margin-top: 8px;

  p {
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #909291;
    text-align: start;
  }
}

.inputAboutContainer {
  width: 100%;
  height: 273px;
  display: flex;
  justify-content: center;

  .inputAbout {
    font-family: Segoe UI;
    width: 100%;
    height: 263px;
    padding: 16px;
    border: none;
    outline: none;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    color: #000000;
    border-radius: 10px;
    background-color: #ffffff;
    resize: none;
    text-align: start;

    &::placeholder {
      text-align: start;
    }

    &::-webkit-input-placeholder {
      text-align: start;
    }
  }
}

.inputWithIcon {
  margin-top: -10px;
  position: relative;
  .inputQuestion {
    cursor: pointer;
    width: 100%;
    margin-top: 12px;
    height: 58px;
    padding: 0px 16px;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    color: #000000;
    text-align: start;

    &::placeholder {
      text-align: start;
      vertical-align: top;
    }
  }
}
