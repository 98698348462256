h1 {
  margin: 0;
}
.titleWrap {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: left;


  .firstTitle {
    margin: 0;
  }
  .lastTitle {
    margin-top: -1px;
  }
}
