@import "scss/colors";
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tab {
  padding: 8px 10px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: #98989e;
  width: 100%;
  transition: 0.2s;
  cursor: pointer;

  svg {
    margin-bottom: -3px;
  }

  &_active {
    color: $blue;
  }
}
