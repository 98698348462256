.container {
  max-width: 390px;
  height: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f7;
  border-radius: 10px;
  position: relative;
}
