.container {
  padding: 0 16px;
}

.addPhoto {
  width: 175px;
  height: 175px;
}

.nameAndAge {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 24px;

  .name {
    margin-right: 8px;
  }
}

.title,
.userName {
  margin-bottom: 12px;
}

.userInfo,
.userDescription {
  margin-top: 16px;
  margin-bottom: 12px;
}
