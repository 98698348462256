.logoWrap {
  background: rgba(242, 242, 247, 1);
  border-radius: 30px 30px 0 0;
  margin-bottom: 16px;

  .logo {
    background: linear-gradient(92.62deg, #53ade9 15.9%, #27547b 100.17%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin: 10px auto;
    display: inline-block;
  }
}
