.container {
  position: relative;
  max-height: 100vh; // Ограничиваем высоту экрана
  overflow: hidden;

  .image {
    width: 390px;
    height: 592px; // Высота по умолчанию
    border-radius: 15px 0px 0px 0px;
    transition: height 0.3s ease; // Анимация изменения высоты

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(85%);
    }

    &.expanded {
      height: 273px; // Высота изображения при раскрытии текста
    }
  }

  .info {
    position: absolute;
    top: 386px;
    padding: 0 16px;
    transition: all 0.3s ease;
    overflow-y: hidden;
    overflow-y: auto;

    &.expanded {
      margin-top: 8px;
      position: relative;
      top: unset; // Перемещаем блок под фото
      max-height: calc(
        100vh - 273px
      ); // Ограничиваем высоту info при уменьшении фото
    }

    .name {
      display: flex;
      align-items: center;
      align-content: center;
      margin-bottom: 16px;

      p {
        color: rgba(255, 255, 255, 1);
        font-size: 22px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        margin-right: 8px;
        &.expanded {
          color: black; // Изменение цвета текста на черный при раскрытии
        }
      }
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      color: rgba(255, 255, 255, 1); // Цвет по умолчанию — белый
      cursor: pointer;

      &.expanded {
        color: black; // Изменение цвета текста на черный при раскрытии
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .reaction {
        display: flex;
        justify-content: space-between;
        margin-left: 64px;
        width: 160px;
        &.expanded {
          margin-left: 99px;
        }
      }
      &.expanded {
        margin-bottom: 24px;
      }
    }
  }
}
