@import "src/scss/colors";

.button {
  padding: 12px;
  background-color: $blue;
  color: #fff;
  height: 50px;
  border: 1px solid transparent;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  box-shadow: 0 2px 8px 0 #0000002e;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;

  &:active {
    box-shadow: none;
  }

  &:hover {
    border: 1px solid $blue;
    background-color: #fff;
    color: $blue;
  }
}

.disabled {
  box-shadow: none;
  color: $textDisabled;
  background-color: $disabled;
  cursor: not-allowed;

  &:hover {
    border: 1px solid transparent;
    color: $textDisabled;
    background-color: $disabled;
  }
}

.previous,
.cancel,
.like {
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 1);
  width: 55px;
  height: 55px;
}

.previous {
  width: 42px;
  height: 42px;
}

.reload {
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 41px;
  transform: translateY(-50%);
  cursor: pointer;
  border: none; 
  box-shadow: none;
  transition: transform 0.3s ease;
  &:hover{
    border: none;
  }
  &:active{
        transform: translateY(-50%) rotate(-180deg); 
  }
}
