.addPhotoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circlePreview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  margin: 0 auto 8px;

  .circleImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.previewImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  background-color: rgba(255, 255, 255, 1);
}

.hiddenInputBtn {
  display: none;
}
