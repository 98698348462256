.registerStepTwo {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f2f2f7;
  padding: 0 15px;
}

.title {
  left: 16px;
  margin-top: 28px;
  margin-bottom: 8px;
}

.inputAbout {
  margin-top: 16px;
}

.button {
  position: absolute;
  bottom: 68px;
  left: 16px;
  right: 16px;
  margin-top: 139px;
}
