.statusContainer {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  align-content: center;

  p {
    display: flex;
    justify-content: space-between;
    padding: 4px 12px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 1);
    &:first-child {
      margin-right: 8px;
    }

    span {
      margin-right: 4px;
      display: flex;
      align-items: center;
      align-content: center;
    }
  }
  .blackLocation {
    width: 16px;
    height: 16px;
    stroke: black;
  }
}
