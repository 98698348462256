.container {
  max-height: calc(100% - 65px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content{
  overflow-y: auto;
}