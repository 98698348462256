.container {
  height: 60px;
  padding: 19px 16px;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;

  input {
    width: 325px;
    height: 100%;
    border: none;
    outline: none;
    background-color: inherit;
    font-size: 17px;
    color: #000000;

    &::placeholder {
      color: #98989e;
      font-size: 17px;
    }
  }

  .contentContainer {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }

  .checkIcon {
    border-radius: 12px;
    background-color: #34c759;
    color: #ffffff;
  }
}
