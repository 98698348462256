.userRegistration {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f2f2f7;
  padding: 0 16px;
}

.title {
  margin-bottom: 24px;
}

.button {
  position: absolute;
  bottom: 68px;
  left: 16px;
  right: 16px;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
